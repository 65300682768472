import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 6-6-6-6\\@80% 1RM`}</p>
    <p>{`GHR’s 6-6-6-6`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`400M Run`}</p>
    <p>{`100ft Backwards Sled Drag (135/90)`}</p>
    <p>{`10 Burpee Box Jumps (24/20)`}</p>
    <p>{`5 D Ball Cleans (80/40)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      